@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

.scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #666666;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}

.scrollbar::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.code code {
	color:#863532;
	display:block;
	background-color: #eff0f1;
	border-radius:6px;
}

