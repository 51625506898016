.rotate {
  animation: rotate-keyframes 1s;
 }


 @keyframes rotate-keyframes {
  from {
   transform: rotateY(0deg);
  }
 
  to {
   transform: rotateY(180deg);
  }
 }

.scale {
  animation: scale 1.5s;
}

@keyframes scale {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1.0);
  }
}

.translateAndFadeIn{
  animation: translateAndFadeIn 1.5s;
}

@keyframes translateAndFadeIn {
  from {
    transform: translateX(-50%);
    opacity: 0;

  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

 .underline {
  position: relative;
 }

 .underline:before{
    content: "";
    width:80%;
    position: absolute;    
    bottom:0;
    left:-20%;
    height: 1px;
    border-bottom: 3px solid orange;
    animation: translate 2s infinite;
    animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
 }

@keyframes translate {
  from {
    transform: translateX(0)
  }
  to {
    transform: translateX(40%)
  }
}

:root{
  --frameWidth:1024px;
  --frameHeight:600px;
  --Offset:6;
}

.frame {
  overflow: hidden;
  height: var(--frameHeight);
  width: var(--frameWidth);
  color:#000;
  padding: 0;
  border-radius: 8px;
}

.flexImages{
  display: flex;
  position: relative;
  margin: 0 0 0 calc(-1*var(--Offset)*var(--frameWidth));
  animation-name: slide;
  animation-iteration-count: infinite;
  animation-duration: 75s;
  animation-direction: alternate;
  animation-play-state: running;
}

@keyframes slide {
  0%{left:calc(0*var(--frameWidth))}
  4%  {left:calc(1*var(--frameWidth))} 
  8% {left:calc(1*var(--frameWidth))}
  12% {left:calc(2*var(--frameWidth))}
  16% {left:calc(2*var(--frameWidth))}
  20% {left:calc(3*var(--frameWidth))}
  24% {left:calc(3*var(--frameWidth))}
  28% {left:calc(4*var(--frameWidth))}
  32% {left:calc(4*var(--frameWidth))}
  36% {left:calc(5*var(--frameWidth))}
  40% {left:calc(5*var(--frameWidth))}
  44% {left:calc(6*var(--frameWidth))}
  48% {left:calc(6*var(--frameWidth))}
  52%{left:calc(5*var(--frameWidth))}
  56%{left:calc(5*var(--frameWidth))}
  60%{left:calc(4*var(--frameWidth))}
  64%{left:calc(4*var(--frameWidth))}
  68%{left:calc(3*var(--frameWidth))}
  72%{left:calc(3*var(--frameWidth))}
  76%{left:calc(2*var(--frameWidth))}
  80%{left:calc(2*var(--frameWidth))}
  85%{left:calc(1*var(--frameWidth))}
  92%{left:calc(1*var(--frameWidth))}
  100%{left:calc(0*var(--frameWidth))}
}

.container {
  height: var(--frameHeight);
  width: var(--frameWidth);
  padding: 0;
  margin: 0;
}

html,body{
  overflow-x: hidden;
  scroll-behavior: smooth;
}

a:hover{
  text-decoration: none;
}

.centerTitle{
  text-align: center;
  font-size: 28px;
  color: #4296ba;
  font-weight: 600;
  margin-bottom: 2em;
}

.overlay{
  position: absolute;
  top:-100%;
  left:-100%;
  background-color: #ffffffcc;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes cover{
  from {
    transform: translate3d(0,0,0);
  }
  to {
    transform: translate3d(100%,100%,0)
  }
}

.textGradient {
	background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
  -webkit-background-clip: text;
  background-clip: text;
	-webkit-text-fill-color: transparent;
}

.resetMarginsAndPadding p{
  margin: 0%;
}

.blurOverlay{
  position: absolute;
  top:0%;
  left:0%;
  background-color: #eeeeeecc;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

  .blur {
    filter: blur(3px);
  }

.flex{
  display: flex;
}