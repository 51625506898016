@import url(https://fonts.googleapis.com/css?family=Josefin+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollbar::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #666666;
	box-shadow: inset 0 0 6px rgba(0,0,0,.3);
}

.scrollbar::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.code code {
	color:#863532;
	display:block;
	background-color: #eff0f1;
	border-radius:6px;
}


.rotate {
  -webkit-animation: rotate-keyframes 1s;
          animation: rotate-keyframes 1s;
 }


 @-webkit-keyframes rotate-keyframes {
  from {
   -webkit-transform: rotateY(0deg);
           transform: rotateY(0deg);
  }
 
  to {
   -webkit-transform: rotateY(180deg);
           transform: rotateY(180deg);
  }
 }


 @keyframes rotate-keyframes {
  from {
   -webkit-transform: rotateY(0deg);
           transform: rotateY(0deg);
  }
 
  to {
   -webkit-transform: rotateY(180deg);
           transform: rotateY(180deg);
  }
 }

.scale {
  -webkit-animation: scale 1.5s;
          animation: scale 1.5s;
}

@-webkit-keyframes scale {
  from {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  to {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}

@keyframes scale {
  from {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  to {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
  }
}

.translateAndFadeIn{
  -webkit-animation: translateAndFadeIn 1.5s;
          animation: translateAndFadeIn 1.5s;
}

@-webkit-keyframes translateAndFadeIn {
  from {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    opacity: 0;

  }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes translateAndFadeIn {
  from {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    opacity: 0;

  }
  to {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
    opacity: 1;
  }
}

 .underline {
  position: relative;
 }

 .underline:before{
    content: "";
    width:80%;
    position: absolute;    
    bottom:0;
    left:-20%;
    height: 1px;
    border-bottom: 3px solid orange;
    -webkit-animation: translate 2s infinite;
            animation: translate 2s infinite;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    animation-direction: alternate-reverse;
 }

@-webkit-keyframes translate {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0)
  }
  to {
    -webkit-transform: translateX(40%);
            transform: translateX(40%)
  }
}

@keyframes translate {
  from {
    -webkit-transform: translateX(0);
            transform: translateX(0)
  }
  to {
    -webkit-transform: translateX(40%);
            transform: translateX(40%)
  }
}

:root{
  --frameWidth:1024px;
  --frameHeight:600px;
  --Offset:6;
}

.frame {
  overflow: hidden;
  height: 600px;
  height: var(--frameHeight);
  width: 1024px;
  width: var(--frameWidth);
  color:#000;
  padding: 0;
  border-radius: 8px;
}

.flexImages{
  display: -webkit-flex;
  display: flex;
  position: relative;
  margin: 0 0 0 calc(-1*6*1024px);
  margin: 0 0 0 calc(-1*var(--Offset)*var(--frameWidth));
  -webkit-animation-name: slide;
          animation-name: slide;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 75s;
          animation-duration: 75s;
  -webkit-animation-direction: alternate;
          animation-direction: alternate;
  -webkit-animation-play-state: running;
          animation-play-state: running;
}

@-webkit-keyframes slide {
  0%{left:calc(0*1024px);left:calc(0*var(--frameWidth))}
  4%  {left:calc(1*1024px);left:calc(1*var(--frameWidth))} 
  8% {left:calc(1*1024px);left:calc(1*var(--frameWidth))}
  12% {left:calc(2*1024px);left:calc(2*var(--frameWidth))}
  16% {left:calc(2*1024px);left:calc(2*var(--frameWidth))}
  20% {left:calc(3*1024px);left:calc(3*var(--frameWidth))}
  24% {left:calc(3*1024px);left:calc(3*var(--frameWidth))}
  28% {left:calc(4*1024px);left:calc(4*var(--frameWidth))}
  32% {left:calc(4*1024px);left:calc(4*var(--frameWidth))}
  36% {left:calc(5*1024px);left:calc(5*var(--frameWidth))}
  40% {left:calc(5*1024px);left:calc(5*var(--frameWidth))}
  44% {left:calc(6*1024px);left:calc(6*var(--frameWidth))}
  48% {left:calc(6*1024px);left:calc(6*var(--frameWidth))}
  52%{left:calc(5*1024px);left:calc(5*var(--frameWidth))}
  56%{left:calc(5*1024px);left:calc(5*var(--frameWidth))}
  60%{left:calc(4*1024px);left:calc(4*var(--frameWidth))}
  64%{left:calc(4*1024px);left:calc(4*var(--frameWidth))}
  68%{left:calc(3*1024px);left:calc(3*var(--frameWidth))}
  72%{left:calc(3*1024px);left:calc(3*var(--frameWidth))}
  76%{left:calc(2*1024px);left:calc(2*var(--frameWidth))}
  80%{left:calc(2*1024px);left:calc(2*var(--frameWidth))}
  85%{left:calc(1*1024px);left:calc(1*var(--frameWidth))}
  92%{left:calc(1*1024px);left:calc(1*var(--frameWidth))}
  100%{left:calc(0*1024px);left:calc(0*var(--frameWidth))}
}

@keyframes slide {
  0%{left:calc(0*1024px);left:calc(0*var(--frameWidth))}
  4%  {left:calc(1*1024px);left:calc(1*var(--frameWidth))} 
  8% {left:calc(1*1024px);left:calc(1*var(--frameWidth))}
  12% {left:calc(2*1024px);left:calc(2*var(--frameWidth))}
  16% {left:calc(2*1024px);left:calc(2*var(--frameWidth))}
  20% {left:calc(3*1024px);left:calc(3*var(--frameWidth))}
  24% {left:calc(3*1024px);left:calc(3*var(--frameWidth))}
  28% {left:calc(4*1024px);left:calc(4*var(--frameWidth))}
  32% {left:calc(4*1024px);left:calc(4*var(--frameWidth))}
  36% {left:calc(5*1024px);left:calc(5*var(--frameWidth))}
  40% {left:calc(5*1024px);left:calc(5*var(--frameWidth))}
  44% {left:calc(6*1024px);left:calc(6*var(--frameWidth))}
  48% {left:calc(6*1024px);left:calc(6*var(--frameWidth))}
  52%{left:calc(5*1024px);left:calc(5*var(--frameWidth))}
  56%{left:calc(5*1024px);left:calc(5*var(--frameWidth))}
  60%{left:calc(4*1024px);left:calc(4*var(--frameWidth))}
  64%{left:calc(4*1024px);left:calc(4*var(--frameWidth))}
  68%{left:calc(3*1024px);left:calc(3*var(--frameWidth))}
  72%{left:calc(3*1024px);left:calc(3*var(--frameWidth))}
  76%{left:calc(2*1024px);left:calc(2*var(--frameWidth))}
  80%{left:calc(2*1024px);left:calc(2*var(--frameWidth))}
  85%{left:calc(1*1024px);left:calc(1*var(--frameWidth))}
  92%{left:calc(1*1024px);left:calc(1*var(--frameWidth))}
  100%{left:calc(0*1024px);left:calc(0*var(--frameWidth))}
}

.container {
  height: 600px;
  height: var(--frameHeight);
  width: 1024px;
  width: var(--frameWidth);
  padding: 0;
  margin: 0;
}

html,body{
  overflow-x: hidden;
  scroll-behavior: smooth;
}

a:hover{
  text-decoration: none;
}

.centerTitle{
  text-align: center;
  font-size: 28px;
  color: #4296ba;
  font-weight: 600;
  margin-bottom: 2em;
}

.overlay{
  position: absolute;
  top:-100%;
  left:-100%;
  background-color: #ffffffcc;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

@-webkit-keyframes cover{
  from {
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
  }
  to {
    -webkit-transform: translate3d(100%,100%,0);
            transform: translate3d(100%,100%,0)
  }
}

@keyframes cover{
  from {
    -webkit-transform: translate3d(0,0,0);
            transform: translate3d(0,0,0);
  }
  to {
    -webkit-transform: translate3d(100%,100%,0);
            transform: translate3d(100%,100%,0)
  }
}

.textGradient {
	background: linear-gradient(to right, #30CFD0 0%, #330867 100%);
  -webkit-background-clip: text;
  background-clip: text;
	-webkit-text-fill-color: transparent;
}

.resetMarginsAndPadding p{
  margin: 0%;
}

.blurOverlay{
  position: absolute;
  top:0%;
  left:0%;
  background-color: #eeeeeecc;
  height: 100%;
  width: 100%;
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

  .blur {
    -webkit-filter: blur(3px);
            filter: blur(3px);
  }

.flex{
  display: -webkit-flex;
  display: flex;
}
